/* @flow */

import * as React from 'react'
import { HomepageTemplate } from './../templates'

type Props = {
  location: {
    href: string,
  },
}

export default function Homepage(props: Props) {
  const { location } = props
  return (
    <HomepageTemplate
      url={location.href}
      name="Wino"
      title="Le logiciel de caisse enregistreuse idéal pour caviste et épicerie"
      description="La caisse connectée sur iPad pour les commerces de proximité : cavistes, épiceries fines et fromageries. Centralisez les activités de votre commerce avec un logiciel de caisse enregistreuse tout-en-un."
    />
  )
}
